// Live Site
export const API_BASE_URL = "https://building.bmaus.com.au/BMSBackend/"; 
export const API_BASE_CLIENT_URL = "https://building.bmaus.com.au/client/";
export const CLIENT_SITE_URL = "https://building.bmaus.com.au/";
export const BASE_PATH = "";


// Test Site
// export const API_BASE_URL = "https://staging.debcomsolutions.com/bms/BMSBackend/"; 
// export const API_BASE_CLIENT_URL = "https://staging.debcomsolutions.com/bms/client/";
// export const CLIENT_SITE_URL = "https://staging.debcomsolutions.com/bms/";
// export const BASE_PATH = "/bms";



// Local Site
// export const API_BASE_URL = "http://localhost/bms/BMSBackend/";
// export const API_BASE_CLIENT_URL = "http://localhost/bms/client/";
// export const CLIENT_SITE_URL = "http://localhost/bms/";
// export const BASE_PATH = "/bms";

export const BUILDING_UPLOADING_PATH = 'uploads/buildings/';
export const BUILDING_PDF_PATH = 'uploads/buildingPdf/';
export const MOVE_IN_QR_CODE = 'uploads/movein/';
export const MOVE_OUT_QR_CODE = 'uploads/moveout/';
export const ATTENDEE_PDF = 'uploads/attendee/';

