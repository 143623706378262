import React from 'react'
import * as config from '../utilities/config';

const Logout = () => {

    const type = localStorage.getItem("loginType")
    if(type === "admin")
    {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("authKey");
        window.location.replace(config.BASE_PATH+'/admin');
        // window.location.replace('/admin');
    }
    else if(type === "user")
    {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("authKey");
        window.location.replace(config.BASE_PATH+'/admin');
        // window.location.replace('/admin');
    }


  return (
    <></>
  )
}

export default Logout