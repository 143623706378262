import React from 'react'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import validator from 'validator';
import axios from 'axios';
import * as config from '../utilities/config';
import Navbar from './includes/Navbar';
import routes from './routesConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as SessionMsg from './SwalSessionExpire'

const EditProfile = () => {


    document.title = "BMA : Edit Profile";


const [adminProfileDet, setAdminProfileDet] = useState({name:'',email:''});
const [userProfileDet, setUserProfileDet] = useState({f_name:'',l_name:'',email:''});



axios.defaults.headers.common['Authorization'] = `${localStorage.getItem('authKey')}`;
axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';


    
const alertSuccess = (data) => toast.success(data, { 
    position: "top-center", 
    autoClose: 10000, 
    hideProgressBar: false, 
    closeOnClick: true, 
    pauseOnHover: true, 
    draggable: true, 
    progress: undefined, 
    theme: "light",
});

const alertError = (data) => toast.error(data, {
    position: "top-center",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

const alertWarning = (data) => toast.warn(data, {
    position: "top-center",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });


const updateProfileHandler = (event) => {
    event.preventDefault();

    const postData = new FormData();
    
    if(localStorage.getItem('loginType')==='admin')
    {
        postData.append('id',localStorage.getItem('adminId'));

        // validation logic
        if(adminProfileDet.name === '')
        {
            alertWarning("Name is required");
            return false;
        }

        if(adminProfileDet.email !== '')
        {
            if(validator.isEmail(adminProfileDet.email))
            {
                // alert('Valid email');
                // setError({...error,email_err:false});
            
            }
            else
            {
                alertWarning('Please enter valid email');
                return false;
            }
        }
        else
        {
            alertWarning('Please enter email');
            return false;
        }
        postData.append('flag','UPDATE_ADMIN');
        postData.append('name',adminProfileDet.name);
        postData.append('email',adminProfileDet.email);
    }
    else if(localStorage.getItem('loginType')==='user')
    {
        postData.append('id',localStorage.getItem('userId'));

        // validation logic
        if(userProfileDet.f_name === '')
        {
            alertWarning("First Name is required");
            return false;
        }

        if(userProfileDet.l_name === '')
        {
            alertWarning("Last Name is required");
            return false;
        }

        if(userProfileDet.email !== '')
        {
            if(validator.isEmail(userProfileDet.email))
            {
                // alert('Valid email');
                // setError({...error,email_err:false});
            
            }
            else
            {
                alertWarning('Please enter valid email');
                return false;
            }
        }
        else
        {
            alertWarning('Please enter email');
            return false;
        }

        postData.append('flag','UPDATE_USER');
        postData.append('f_name',userProfileDet.f_name);
        postData.append('l_name',userProfileDet.l_name);
        postData.append('email',userProfileDet.email);
    }


    updateProfileDetails(postData);
    // console.log(postData);


    
}


const profileDetHandler = () => {
    const postData = new FormData();

    if(localStorage.getItem('loginType')==='admin')
    {
        postData.append('id',localStorage.getItem('adminId'));
        postData.append('flag','ADMIN_DETAILS');
    }
    else if(localStorage.getItem('loginType')==='user')
    {
        postData.append('id',localStorage.getItem('userId'));
        postData.append('flag','USER_DETAILS');
    }

    getProfileDetails(postData);
}


// get profile details
async function getProfileDetails(postData) {
    try{
        const res = await axios.post(config.API_BASE_URL+'updateProfile.php',postData);


        if(res.data === "AUTH_KEY_NOT_PROVIDED" || res.data === "VALIDATION_TIME_ERROR" || res.data === "VALIDATION_ERROR")
        {
            SessionMsg.swalSessionExpire();
        }
        else
        {
            // console.log(res.data);
            // return false;

            if(localStorage.getItem('loginType')==='admin')
            {
                setAdminProfileDet({...adminProfileDet,name:res.data.user_name,email:res.data.email});
            }
            else if(localStorage.getItem('loginType')==='user')
            {
                setUserProfileDet({...userProfileDet,f_name:res.data.first_name,l_name:res.data.last_name,email:res.data.email});
            }
        
        }
    }
    catch(err){
        console.log(err);
    }
}

// update profile details
async function updateProfileDetails(postData) {
    try{
        const res =  await axios.post(config.API_BASE_URL+'updateProfile.php',postData);

        if(res.data === "AUTH_KEY_NOT_PROVIDED" || res.data === "VALIDATION_TIME_ERROR" || res.data === "VALIDATION_ERROR")
        {
            SessionMsg.swalSessionExpire();
        }
        else
        {
            // console.log(res.data);

            if(res.data === "SUCCESS")
            {
                alertSuccess("Profile Updated Successfully");
            }
            else if(res.data === "EMAIL_ALREADY_EXIST")
            {
                alertError("Email Already Exist");
            }
            else if(res.data === "ERROR")
            {
                alertError("Unknown Error Occured");
            }
        
        }


    }
    catch(err){
        console.log(err);
    }
}





useEffect(() => {
    profileDetHandler();
},[]);
   


  return (
    <>
    
    <section className="container-fluid">
            <div className="row">
                <Navbar links={routes} />
                <div className="col-md-10">
                    <div className='bms_Right'>
                        <div className='row'>
                            <ToastContainer position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light" 
                            />
                            <div className='col'></div>
                            <div className='col-md-6'>
                                <h1>Edit Profile</h1>
                                <div className='col-md-12 clearfix'>&nbsp;</div>
                                <div className='row'>
                                    {localStorage.getItem('loginType')==='admin' ?(
                                        <>
                                            <div className='col-md-12'>
                                                <label>Name: </label>
                                                <input type='text' name='name' placeholder='Enter Name' onChange={(e)=>{setAdminProfileDet({...adminProfileDet,[e.target.name]:e.target.value})}} value={adminProfileDet.name} />
                                            </div>
                                            <div className='col-md-12'>
                                                <label>Email: </label>
                                                <input type='email' name='email' placeholder='Enter Email' onChange={(e)=>{setAdminProfileDet({...adminProfileDet,[e.target.name]:e.target.value})}} value={adminProfileDet.email} />
                                            </div>
                                        </>

                                    ):(
                                        <>
                                            <div className='col-md-12'>
                                                <label>First Name: </label>
                                                <input type='text' name='f_name' placeholder='Enter First Name' onChange={(e)=>{setUserProfileDet({...userProfileDet,[e.target.name]:e.target.value})}} value={userProfileDet.f_name} />
                                            </div>
                                            <div className='col-md-12'>
                                                <label>Last Name: </label>
                                                <input type='text' name='l_name' placeholder='Enter Last Name' onChange={(e)=>{setUserProfileDet({...userProfileDet,[e.target.name]:e.target.value})}} value={userProfileDet.l_name} />
                                            </div>
                                            <div className='col-md-12'>
                                                <label>Email: </label>
                                                <input type='email' name='email' placeholder='Enter Email' onChange={(e)=>{setUserProfileDet({...userProfileDet,[e.target.name]:e.target.value})}} value={userProfileDet.email} />
                                            </div>
                                        </>
                                    )}

                                    <div className='col-md-12'>
                                        <Button variant="contained" className='add_butt' onClick={updateProfileHandler}>Submit</Button>
                                    </div>
                                </div>
                            </div>
                            <div className='col'></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </>
  )
}


export default EditProfile