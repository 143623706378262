import React from 'react'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import { Link, json } from 'react-router-dom';
import axios from 'axios';
import * as config from '../utilities/config';
import Navbar from './includes/Navbar';
import routes from './routesConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as SessionMsg from './SwalSessionExpire';
import PasswordChecklist from "react-password-checklist";
import { useLocation } from 'react-router-dom';



const SetPass = () => {


    axios.defaults.headers.common['Authorization'] = `${localStorage.getItem('authKey')}`;
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';

    document.title = "BMA : Set Password";

    const [email, setEmail] = useState('');
    const [Fname, setFname] = useState('');
    const [Lname, setLname] = useState('');
    const [password,setPassword] = useState({pass:'',confPass:''});
    const [submitBtn, setSubmitBtn] = useState(true);
    const [passwordError, setPasswordError] = useState(true); 
    const [uidValid, setUidValid] = useState(true); 

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const uid = queryParams.get('uid');

    // console.log(uid);

    const changeHandler = (event) => {
    setPassword({...password,[event.target.name]:event.target.value});
    }
        
    const alertSuccess = (data) => toast.success(data, { 
        position: "top-center", 
        autoClose: 10000, 
        hideProgressBar: false, 
        closeOnClick: true, 
        pauseOnHover: true, 
        draggable: true, 
        progress: undefined, 
        theme: "light",
    });

    const alertError = (data) => toast.error(data, {
        position: "top-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    const alertWarning = (data) => toast.warn(data, {
        position: "top-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });


    // submit handler with validations
    const submitHandler = (event) => 
    {
        event.preventDefault();

        const postData = new FormData();
        postData.append('password',password.pass);
        postData.append('confirmPassword',password.confPass);
        postData.append('flag','SET_PASSWORD');
        postData.append('uid',uid);

        // console.log(postData);
        
        updatePassword(postData);


    }


    // update password
    async function updatePassword(postData) 
    {
        try{
            const res = await axios.post(config.API_BASE_URL+'setPass.php',postData);
            // console.log(res);

            if(res.data === "AUTH_KEY_NOT_PROVIDED" || res.data === "VALIDATION_TIME_ERROR" || res.data === "VALIDATION_ERROR")
            {
                SessionMsg.swalSessionExpire();
            }
            else
            {
                if(res.data === "SUCCESS")
                {
                    alertSuccess("Password Set Successfully");
                    setPasswordError(false);
                    setSubmitBtn(true);
                }
                else if(res.data === "PASSWORD_NOT_MATCH")
                {
                    alertError("Password Not Match");
                    setPasswordError(true);
                }
                else if(res.data === "TOKEN_NOT_PROVIDED" || res.data === "INVALID_TOKEN")
                {
                    alertError("Password Set Failed: Invalid ID / ID Not Provided");
                    setPasswordError(true);
                }
                // console.log(res.data);
            }

        }
        catch(err){
            console.log(err);
        }

    }


        // get uid info
        async function getUserDetByID(postData) 
        {
            try{
                const res = await axios.post(config.API_BASE_URL+'setPass.php',postData);
                // console.log(res);
    
                if(res.data === "AUTH_KEY_NOT_PROVIDED" || res.data === "VALIDATION_TIME_ERROR" || res.data === "VALIDATION_ERROR")
                {
                    SessionMsg.swalSessionExpire();
                }
                else
                {
                    const dataArr = res.data.split("<@_@>");
                    if(dataArr[0] === "SUCCESS")
                    {
                        const userDet = JSON.parse(dataArr[1]);
                        setEmail(userDet.email);   
                        setFname(userDet.first_name);   
                        setLname(userDet.last_name);   
                        setUidValid(true);
                       
                    }
                    else if(dataArr[0] === "USER_ID_NOT_PROVIDED" || dataArr[0] === "INVALID_USER_ID")
                    {
                        alertError("Invalid User Id / User Id Not Provided");
                        setPasswordError(true);
                        setUidValid(false);
                    }
                    // console.log(dataArr);
                }
    
            }
            catch(err){
                console.log(err);
            }
    
        }

        useEffect(() => {
            const postData = new FormData();
            postData.append('flag','GET_ID_DET');
            postData.append('uid',uid);
            getUserDetByID(postData);
        },[])


  return (
    <>
    
    <section className="container-fluid">
        <div className='row'>
            <Navbar links={routes} />
            <div className="col-md-10">
                <div className='bms_Right'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <ToastContainer
                                position="top-center"
                                autoClose={6000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="colored"
                                style={{width:"600px"}}
                            />
                        </div>
                        <div className='col'></div>
                           
                        <div className='col-md-6 '>
                        <div className='fullNameStyle'>User Name: {Fname} {Lname}</div>  
                            <div className='loginHolder'>
                            <h1>Set Password</h1> 
                            {uidValid ? <>

                            <fieldset>
                            <legend>Email</legend>
                                <input type="email" name='pass' placeholder="Password" disabled="true"  value={email} />
                            </fieldset>

                            <fieldset>
                            <legend>Password</legend>
                                <input type="password" name='pass' placeholder="Password" disabled={!passwordError} onChange={changeHandler} value={password.pass} />
                            </fieldset>

                            <fieldset>
                            <legend>Confirm Password</legend>
                                <input type='password' name='confPass' placeholder="Confirm Password" disabled={!passwordError} onChange={changeHandler} value={password.confPass} />
                            </fieldset>
                            
                            
                            <PasswordChecklist
                                rules={["minLength","specialChar","number","capital","match"]}
                                minLength={8}
                                value={password.pass}
                                valueAgain={password.confPass}
                                onChange={(isValid) => {setSubmitBtn(!isValid);}}
                                />
                                            


                            <Button className={submitBtn ? 'resetPassBtnDisable': 'resetPassBtnEnable'}  variant="contained" disabled={submitBtn} onClick={submitHandler} size="large">Set Password</Button>&nbsp;
                           
                            </>:<h3 style={{color:'red',fontWeight:'600'}}>Invalid ID</h3>}

                            <div className='clearfix'>&nbsp;</div>
                            <div className='clearfix'>&nbsp;</div>
                            <div className='clearfix'>&nbsp;</div>
                            <div className='clearfix'>&nbsp;</div>
                            </div>    
                        </div>
                        <div className='col'></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    </>
  )
}

export default SetPass