import React from 'react'

const AccessDenied = () => {
  return (
    <>
    
    <div className='container'>
        <h1>Access Denied</h1>
        <p>You do not have access to this page.</p>
    </div>
    
    </>
  )
}

export default AccessDenied