import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios';
import * as config from '../utilities/config';
import * as SessionMsg from './SwalSessionExpire'
import { useParams, useLocation } from 'react-router-dom';

const BuildingViewPublic = () => {

    document.title = "BMA : View Building";

    const [buildingDet, setBuildingDet] = useState('loading');
    const [buildingServiceDet , setBuildingServiceDet] = useState([]);
    const [buildingFilesDet , setBuildingFilesDet] = useState([]);

    const { number } = useParams();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const flag = queryParams.get('flag');

    // console.log('this is id -> '+ buildid)


    const buildingNameHandler = (buildName) => {

        let build_name_arr = buildName.split(" ");

        let building_lname = build_name_arr.pop();
        let building_fname = build_name_arr;

        // return build_name_arr.pop() + " " + building_fname;
        // return building_fname.join(" ") + " " + building_lname;

        return (<><span className="buildingColorName">{building_fname.join(" ")}</span> {building_lname}</>);
    }



    // get type def of number variable
    // console.log(number);

    // for fetching users from database
    async function getBuildingDet(postData) {
        try {
            const res = await axios.post(config.API_BASE_URL + 'stratanumber.php', postData);
            // console.log(res);

            if (res.data === "AUTH_KEY_NOT_PROVIDED" || res.data === "VALIDATION_TIME_ERROR" || res.data === "VALIDATION_ERROR") {
                SessionMsg.swalSessionExpire();
            }
            else {
                const receivedData = res.data;
                const dataArr = receivedData.split('<@_@>');
                // console.log(JSON.parse(dataArr[0]));
                // console.log(JSON.parse(dataArr[1]));
                // console.log(JSON.parse(dataArr[2]));
                // console.log(dataArr[0]);
                if (dataArr[0] !== 0) 
                {
                    setBuildingDet(JSON.parse(dataArr[0]));
                }
                else 
                {
                    setBuildingDet('');
                }

                setBuildingServiceDet(JSON.parse(dataArr[1]));
                setBuildingFilesDet(JSON.parse(dataArr[2]));

            }


        }
        catch (err) {
            console.log(err);
        }
    }

    let buildingPdf = '';
    if (buildingDet.building_pdf !== undefined) {
        buildingPdf = buildingDet.building_pdf.replace(/^\d+_-_/, '');
    }

    useEffect(() => {
        const postData = new FormData();
        postData.append('flag', 'GET_BUILDING_BY_STRATA');
        postData.append('strata_number', number);

        if (flag === 'preview') {
            postData.append('preview_flag', flag);
        }

        getBuildingDet(postData);
    }, []);


    return (
        <>
            <title>Building Details</title>
            {buildingDet === 'loading' ? (
                <div className='loadingDiv'>
                    <div className="spinner"></div>
                    <label className='loadingLabel'>Loading...</label>
                </div>) :
                buildingDet ? (
                    <>
                        <div className="fixed-top-nav">
                            <div className="navbar_holder fixed-top clearfix">
                                <ul>
                                    <li><a href="#Home">Home</a></li>
                                    <li><a href="#About">About</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="base_Container">
                            <div className="bms_NewHolder" id="Home">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="bmsleftBase">
                                                <div className="newlogo "><img data-aos="slide-up" src={config.API_BASE_URL + "images/newLogo.png"} alt="New Logo" /></div>
                                                <div className="gray_St_Bondi">
                                                    {buildingDet.building_address} <span>{buildingDet.building_name}</span>
                                                </div>
                                                <div className="strata">Strata: <span className='buildingColorName'>{buildingDet.strata_number}</span></div>
                                                <div className="buildManager">Building Manager: <span className='buildingColorName'>{buildingDet.manager_name}</span></div>
                                                <div className="buildManager">Building Information:</div>
                                                <div className="boxBuildManage biStyle">
                                                    <p>{buildingDet.additional_info}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="rightimgesBase active">
                                                <img src={config.API_BASE_URL + config.BUILDING_UPLOADING_PATH + buildingDet.building_image} alt="BuidPublicImg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bms_services" id="About">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="bmsServiceLeft">
                                                <div className="gray_Service">Move In, Move Out</div>
                                                <div className="boxBuildManage">
                                                    <p>{buildingDet.move_in_out}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="bmsServiceLeft">
                                                <div className="gray_Service">Building services</div>
                                                <div className="boxBuildManage">
                                                    <div className="teble-responsive">
                                                        <table className="table table-striped">
                                                            <thead></thead>
                                                            <tbody>
                                                                {buildingServiceDet.length > 0 ? buildingServiceDet.map((service, index) => (
                                                                <tr>
                                                                    <td width="250px">{service.title}</td>
                                                                    <td width="250px">{service.name}</td>
                                                                    <td width="250px">{service.contact_no}</td>
                                                                </tr>
                                                                )):''}
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="bmsServiceLeft">
                                                <div className="down_Service">DOWNLOADS</div>
                                                {buildingFilesDet.length > 0 ? buildingFilesDet.map((fileDet, index) => ( 
                                                    <a href={config.API_BASE_URL+config.BUILDING_PDF_PATH + fileDet.file_name} target='_blank' title={fileDet.file_name} className="strataPlan">{fileDet.label}</a>
                                                )):''}
                                                
                                                <div className="space"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    ):(
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div className="building_error_public_container">
                                    <h1 align="center" style={{ textTransform: 'none' }}>Error: Building not found!</h1>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                    )}
                    <div className="footer_base">
                        <p>Copyright © Building Management Australia | All rights reserved</p>
                    </div>
        </>
        );
};

export default BuildingViewPublic